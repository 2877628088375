.block-news-events {
	&__row-cards {
		.columns {
			&:first-child {
				margin-top: 0;
			}

			margin-top: 2.5rem;

			@include media-min($medium) {
				margin-top: 0;
			}

			@for $i from 0 through 2 {
				&:nth-child(#{$i + 1}) {
					/* prettier-ignore */
					transition: opacity #{$default-animation-speed-unitless / 2}s $default-transition-easing #{($default-animation-speed-unitless / 2) * $i}s;
				}
			}

			.block-animate & {
				opacity: 0;
			}
		}

		.block-news-events__row-title + & {
			margin-top: 4rem;

			@include media-min($large) {
				margin-top: 3.5rem;
			}
		}
	}

	&__row-bottom-button {
		margin-top: 4rem;
	}

	&__col-links {
		.card-blog--simple {
			border-bottom: 1px solid #e7e3e0;

			&:first-child {
				.card-blog__content {
					padding-top: 0;
				}
			}
		}
	}

	&__title-container {
		@include media-max($medium) {
			text-align: center;
		}
	}

	&__archive-link-container {
		@include media-min($medium) {
			text-align: right;
		}

		@include media-min($large) {
			text-align: left;
		}
	}

	&__button-archive {
		@include media-min($large) {
			margin-left: 2.5rem;
		}
	}
}
