$hamburger-layer-width: 20px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 4px;

html.mobile-menu-active {
	@include media-max($large) {
		overflow-y: hidden;
	}
}

.site-header {
	z-index: $z-header;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	background: $color-white;
	transition: all ($default-transition-speed * 2) ease-in-out;

	height: $header-height-sm;

	body.admin-bar & {
		top: 46px;
	}

	@include media-min($medium) {
		height: $header-height-md;
	}

	@include media-min($large) {
		height: $header-height-lg;

		html.desktop-search-active & {
			transform: translateY($search-container-height);
		}

		body.admin-bar & {
			top: 32px;
		}
	}

	html.scroll-down:not(.menu-dropdown-active):not(.desktop-search-active) & {
		transform: translateY(-105%);
	}

	html.scrolled &,
	html.menu-dropdown-active & {
		box-shadow: 0 0 5px rgba($color-black, 0.4);
	}

	&__search-link {
		margin: 0;
		color: $color-white;
		cursor: pointer;
	}

	&__search-icon {
		margin-top: 1px;
		width: 15px;
		height: 15px;
	}

	&__inner {
		@include custom-foundation-row-padding(false);

		position: relative;
		width: 100%;
		max-width: $global-width;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&--main {
			align-items: stretch;
			height: 100%;
		}
	}

	&__logo-container {
		display: flex;
		align-items: center;
	}

	&__logo {
		position: relative;
		z-index: $z-header + 2;
		height: 44px;

		@include media-min($medium) {
			height: 5vw;
			max-height: 55px;
		}

		@include media-min($large) {
			height: 4.5vw;
			max-height: 60px;
		}
	}

	&__logo-img {
		height: 100%;
	}

	&__top {
		display: none;

		@include media-min($large) {
			display: block;
			padding: 1rem 0;
		}
	}

	&__main {
		display: flex;
		align-items: center;
		height: 100%;
	}

	&__top-resources,
	&__top-additional,
	&__main-links,
	&__main-buttons {
		display: flex;

		ul {
			display: flex;
		}
	}

	&__top-resources,
	&__top-additional {
		font: normal normal $font-weight-medium 1.3rem / 1.53 $font-poppins;
		letter-spacing: 0.5px;

		a {
			text-decoration: none;
			transition: color $default-transition-speed $default-transition-easing;

			&:hover {
				color: $color-multnomah-gold;
			}
		}

		span,
		li {
			text-transform: uppercase;
		}

		li + li {
			margin-left: 1.6rem;
		}
	}

	&__top-resources {
		li:first-child {
			margin-left: 1.6rem;
		}
	}

	&__top-additional {
		li:last-child {
			margin-right: 1.6rem;
		}
	}

	&__main-links {
		display: none;

		@include media-min($large) {
			flex-grow: 1;
			display: flex;
			justify-content: flex-end;
			margin-right: 3rem;
		}
	}

	&__main-buttons {
		display: flex;
		align-items: center;

		a,
		.site-header__main-buttons-menu {
			@extend %button;

			min-width: 85px;
			min-height: 44px;
		}

		a {
			display: none;

			@include media-min($medium) {
				display: inline-block;
				padding: 1.1rem 2rem;

				@supports (display: inline-flex) {
					display: inline-flex;
					// padding: 0 2rem;
					padding: 0 1rem;
				}
			}
		}

		.site-header__main-buttons-menu {
			padding-top: 0;
			margin-left: 1rem;

			@include media-min($large) {
				display: none;
			}
		}

		li + li {
			margin-left: 1rem;

			@include media-min($large) {
				margin-left: 1.4rem;
			}
		}
	}

	&__mobile-container {
		@include custom-foundation-row-padding(false);

		z-index: $z-header + 1;
		position: fixed;
		top: $header-height-sm;
		right: 0;
		bottom: 0;
		left: 0;
		padding-bottom: 2rem;
		display: flex;
		flex-direction: column;
		transition: opacity 0.25s ease-in-out;
		opacity: 0;
		pointer-events: none;
		background: $color-white;
		overflow-y: scroll;

		html.mobile-menu-active & {
			opacity: 1;
			pointer-events: all;
		}

		@include media-min($medium) {
			top: $header-height-md;
		}

		@include media-min($large) {
			display: none;
		}

		.menu {
			list-style: none;
			margin: 0;
			padding: 0;

			a {
				display: block;
				padding: 2rem 0;
				border-top: 1px solid rgba($color-black, 0.1);
				text-decoration: none;
				font: normal normal $font-weight-medium 1.4rem / 1.4 $font-poppins;
				letter-spacing: 0.25px;
				color: rgba($color-black, 0.5);

				transition: color $default-transition-speed $default-transition-easing;

				&:hover {
					color: $color-multnomah-gold;
				}

				@include media-min($medium) {
					padding: 2rem;
				}
			}
		}
	}

	&__mobile-links-container {
		list-style: none;
		margin: 0;
		padding: 0 2rem 2rem;

		@include media-min($medium) {
			padding-left: 4rem;
		}

		li + li {
			margin-top: 1.5rem;
		}

		a {
			font: normal normal $font-weight-medium 1.4rem / 1.4 $font-poppins;
			text-decoration: none;
			letter-spacing: 0.25px;
			color: rgba($color-black, 0.5);
			transition: color $default-transition-speed $default-transition-easing;

			&:hover {
				color: $color-multnomah-gold;
			}
		}
	}

	&__accordion {
		@include media-min($medium) {
			input[type="checkbox"] + .accordion__section-title,
			input[type="radio"] + .accordion__section-title {
				padding-left: 2rem;
				padding-right: 2rem;
			}
		}

		.accordion__section + .accordion__section {
			border-top: 1px solid rgba($black, 0.1);
		}

		.accordion__section-title {
			flex-direction: row-reverse;
			justify-content: space-between;
		}

		.accordion__section-title-text {
			font: normal normal $font-weight-medium 1.6rem / 1.25 $font-poppins;
			letter-spacing: 0;
		}

		.accordion__section-title-icon {
			margin-right: 0;
		}

		.accordion__section-content {
			padding-left: 0;
		}

		// content is visible
		input:checked ~ .accordion__section-content {
			padding-bottom: 0;
		}
	}

	&__mobile-buttons-menu {
		margin: 0.5rem 0 0;

		.menu {
			ul {
				list-style: none;
				margin: 0;
				padding: 0;
			}

			li + li {
				margin-top: 1.5rem;
			}

			a {
				@extend %button;

				min-width: 85px;
				min-height: 44px;
				color: $color-white;
				transition: background-color $default-transition-speed $default-transition-easing;

				&:hover {
					color: $color-white;
				}
			}
		}
	}

	&__mobile-search {
		margin-top: 1.5rem;
	}

	&__main-nav-link-container {
		margin: 0 1rem;

		@include media-min(1080px) {
			margin: 0 1.8rem;
		}

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}

		&.active,
		&:hover {
			.site-header__main-nav-link::after {
				opacity: 1;
			}
		}

		&.active {
			.menu-dropdown {
				opacity: 1;
				pointer-events: all;
			}
		}
	}

	&__main-nav-link {
		position: relative;
		display: flex;
		align-items: center;
		height: 100%;

		font: normal normal $font-weight-medium 1.6rem / 1.25 $font-poppins;
		letter-spacing: 0.3px;
		color: $color-black-header-links;
		text-decoration: none;

		&::after {
			content: "";
			position: absolute;
			bottom: 37%;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: $color-multnomah-gold;
			opacity: 0;
			pointer-events: none;
			transition: opacity $default-transition-speed ease-in-out;
		}
	}

	&__search-container {
		display: none;

		@include media-min($large) {
			display: flex;
			align-items: center;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: $search-container-height;
			transform: translateY(-#{$search-container-height});
		}
	}

	&__search-container-inner {
		@include custom-foundation-row-padding(false);

		margin: 0 auto;
		width: 100%;
		max-width: #{$global-width - 220px};
		display: flex;
		align-items: center;

		.site-header__search-input {
			width: 100%;
			background-color: transparent;
			border-color: $color-white;

			input {
				background-color: transparent;
				color: $color-white;

				&::placeholder {
					color: rgba($color-white, 0.5);
				}
			}

			.form-field-with-icon__icon {
				color: $color-white;
			}
		}
	}

	&__search-close-button {
		margin-left: 3rem;
		color: $color-multnomah-gold;
		cursor: pointer;
	}

	&__search-close-icon {
		width: 18px;
		height: 18px;
	}
}
