// remove default 'block-inner' top padding from the second block
// in a series of two with the same background color
@each $bg_color_name, $color_values in $bg-colors {
	.block-container.bg-#{$bg_color_name}:not(.block-image):not(.block-image-side-cta):not(.block-testimonial):not(.block-calltoaction--side-image):not(.block-map):not(.block-accordion-with-cta)
		+ .block-container.bg-#{$bg_color_name} {
		.block-inner {
			padding-top: 0;
		}
	}
}

.block-container.block-bg-none:not(.block-image):not(.block-image-side-cta):not(.block-testimonial):not(.block-calltoaction--side-image):not(.block-map):not(.block-accordion-with-cta)
	+ .block-container.block-bg-none {
	.block-inner {
		padding-top: 0;
	}
}

.block-bg-none {
	background-color: $color-white;
}

.block-container {
	overflow-x: hidden;
	z-index: 1;
	position: relative;

	&--below-others {
		z-index: 0;
	}

	.block-inner {
		padding: map-deep-get($block-padding, "small", "top")
			map-deep-get($block-padding, "small", "right")
			map-deep-get($block-padding, "small", "bottom")
			map-deep-get($block-padding, "small", "left");

		@include media-min($medium) {
			padding: map-deep-get($block-padding, "medium", "top")
				map-deep-get($block-padding, "medium", "right")
				map-deep-get($block-padding, "medium", "bottom")
				map-deep-get($block-padding, "medium", "left");
		}

		@include media-min($large) {
			padding: map-deep-get($block-padding, "large", "top")
				map-deep-get($block-padding, "large", "right")
				map-deep-get($block-padding, "large", "bottom")
				map-deep-get($block-padding, "large", "left");
		}
	}
}

.block-options-padding-remove-top .block-inner {
	padding-top: 0;
}

.block-options-padding-remove-bottom .block-inner {
	padding-bottom: 0;
}
