.block-financial-aid-stats {
	&__stat-col-container {
		display: flex;
		align-items: center;
		// justify-content: space-between;
		flex-direction: column;
		// height: 100%;
	}

	&__stat-label {
		margin-top: 4rem;
		font-size: 1.6rem;
		font-weight: $font-weight-bold;
		text-align: center;

		@include media-min($large) {
			max-width: 300px;
		}
	}

	&__circle-container {
		position: relative;
		width: 100%;
		max-width: 200px;
	}

	&__circle-text-container {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&__circle-text-number,
	&__number-text-number {
		font-size: 5.5rem;
		line-height: 1;
	}

	&__circle-text-number {
		margin-top: 2.4rem;
	}

	&__circle-text-percent {
		font-size: 2.5rem;
		line-height: 1;
		color: $color-multnomah-gold;
	}

	&__number-container {
		display: flex;
		align-items: center;
	}

	&__number-text-container {
		display: flex;
		align-items: center;
	}

	&__number-text-prefix {
		font-size: 3.5rem;
		line-height: 1;
		color: $color-multnomah-gold;
		margin-right: 1rem;
	}

	&__number-text-number {
		position: relative;

		&::after {
			content: "";
			position: absolute;
			bottom: -10px;
			left: 0;
			width: 100%;
			height: 4px;
			background-color: $color-multnomah-gold;
		}
	}

	&__circle-svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		overflow: visible;
	}

	&__circle-svg-active-arc {
		stroke: $color-multnomah-gold;
	}

	&__circle-svg-inactive-arc {
		stroke: rgba($color-black, 0.25);
	}

	&__circle-svg-position-circle {
		fill: $color-multnomah-gold;
	}

	&__row-content--two-cols {
		.columns {
			&:last-child {
				padding-top: 4rem;
			}

			@include media-min($medium) {
				&:first-child {
					padding-top: 2rem;
					padding-bottom: 1.5rem;

					border-right: 1px solid $color-multnomah-gold;

					.block-financial-aid-stats__content-col-container {
						padding-right: 3rem;
					}
				}

				&:last-child {
					padding-top: 2rem;
					padding-bottom: 1.5rem;

					.block-financial-aid-stats__content-col-container {
						padding-left: 3rem;
					}
				}
			}
		}
	}

	&__row-content + &__row-stats {
		margin-top: 4rem;
	}

	&__row-stats {
		.columns {
			margin-top: 4rem;
		}
	}
}
