.tmpl-archive-blog {
	padding-top: 1rem;
	padding-bottom: 6rem;

	@include media-min($large) {
		padding-bottom: 8rem;
	}

	.archive__card-column {
		margin-top: 2rem;

		@include media-min($medium) {
			margin-top: 3rem;
		}

		@include media-min($large) {
			margin-top: 4rem;
		}
	}
}
