.block-grid {
	&__items--card {
		margin-top: -2rem;

		@include media-min($medium) {
			margin-top: -3rem;
		}

		@include media-min($large) {
			margin-top: -4rem;
		}

		.columns {
			margin-top: 2rem;

			@include media-min($medium) {
				margin-top: 3rem;
			}

			@include media-min($large) {
				margin-top: 4rem;
			}
		}
	}

	&__items--team-member {
		margin-top: -4rem;

		.columns {
			margin-top: 4rem;
		}
	}

	&__items--image-link,
	&__items--majors {
		margin-top: -2.2rem;

		@include media-min($medium) {
			margin-top: -2.5rem;
		}

		@include media-min($large) {
			margin-top: -2.6rem;
		}

		.columns {
			margin-top: 2.2rem;

			@include media-min($medium) {
				margin-top: 2.5rem;
			}

			@include media-min($large) {
				margin-top: 2.6rem;
			}
		}
	}

	&__items {
		.columns {
			@for $i from 0 through 5 {
				&:nth-child(#{$i + 1}) {
					/* prettier-ignore */
					transition: opacity #{$default-animation-speed-unitless / 2}s $default-transition-easing #{($default-animation-speed-unitless / 2) * $i}s;
				}
			}

			.block-animate & {
				opacity: 0;
			}
		}
	}

	// @include media-max($medium) {
	// 	.image-link {
	// 		padding-top: 44%;
	// 	}
	// }
}
