.banner-home {
	overflow-y: visible;
	background-color: transparent;

	.banner__cover-image-container {
		clip-path: polygon(0% 0%, 100% 0, 100% 94%, 50% 100%, 0% 94%);

		@include media-min($medium) {
			clip-path: polygon(0% 0%, 100% 0, 100% 90%, 50% 100%, 0% 90%);
		}
	}

	&__content {
		@include custom-foundation-row-padding(false);

		display: flex;
		flex-direction: column;

		padding-top: 4rem;
		padding-bottom: 6.5rem;

		@include media-min($medium) {
			padding-top: 6rem;
			padding-bottom: 12.5rem;
		}

		@include media-min($large) {
			padding-top: 8rem;
			padding-bottom: 17rem;
		}
	}

	&__content-text {
		max-width: 80%;

		@include media-min($medium) {
			max-width: 70%;
		}

		@include media-min($large) {
			max-width: 50%;
		}
	}

	&__title {
		font: normal normal $font-weight-black 3.6rem / 1.15 $font-masqualero;

		@include media-min($medium) {
			font-size: 6.5rem;
			line-height: 1.08;
			//letter-spacing: 3px;
		}
	}

	&__form-container {
		margin-top: 3.3rem;

		@include media-min($medium) {
			margin-top: 4.5rem;
		}

		@include media-min($large) {
			max-width: 50%;
		}
	}

	&__form-label {
		font-size: 18px;
		color: rgba($color-white, 0.85);
		letter-spacing: 0.5px;
	}

	&__search-form {
		display: flex;
		flex-direction: column;
		margin-top: 1rem;

		@include media-min($medium) {
			flex-direction: row;
		}

		input[type="text"] {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: auto;
			flex-grow: 1;
			background-color: transparent;
			min-height: 0;
			height: 100%;
			border: 0;
			width: 100%;
			padding-right: 50px;
		}

		input[type="submit"] {
			margin-top: 1.5rem;

			@include media-min($medium) {
				margin-top: 0;
				margin-left: 1rem;
			}
		}
	}

	&__search-input-container {
		z-index: 1;
		position: relative;
		border-radius: $default-input-border-radius;
		border: $input-border;
		padding: 0 20px 0 18px;
		background-color: $color-white;
		min-height: $default-input-min-height;
		font: $body-font;
		font-size: inherit;
		color: $input-text-color;
		width: auto;
		flex-grow: 1;

		@include media-min($large) {
			padding: 0 2rem;
		}
	}

	&__search-input-autocomplete {
		display: flex;
		align-items: center;
		height: 100%;
		opacity: 0;

		&.active {
			opacity: 1;
		}
	}

	&__search-dropdown {
		position: absolute;
		bottom: -1px;
		left: -1px;
		width: calc(100% + 2px);
		border: $input-border;
		border-top: 0;
		background-color: $color-white;
		list-style: none;
		opacity: 0;
		pointer-events: none;
		transition: opacity $default-transition-speed $default-transition-easing;
		transform: translateY(100%);

		&.active {
			pointer-events: all;
			opacity: 1;
		}

		li {
			padding: 0.5rem 2rem;

			&:first-child {
				padding-top: 1rem;
			}

			&:last-child {
				padding-bottom: 1rem;
			}

			a {
				display: block;

				color: $color-major-dropdown !important;
				text-decoration: none;

				transition: color $default-transition-speed $default-transition-easing;

				&:hover {
					color: $color-multnomah-gold !important;
				}
			}
		}
	}

	&__search-loader.ui__spin-loader {
		position: absolute;
		top: 17px;
		right: 15px;

		width: 25px;
		height: 25px;

		border-top-width: 4px;
		border-right-width: 4px;
		border-bottom-width: 4px;
		border-left-width: 4px;

		transition: opacity $default-transition-speed $default-transition-easing;
		opacity: 0;
		pointer-events: none;

		.banner-home__search-form.is-loading & {
			opacity: 1;
		}

		&::after {
			width: 25px;
			height: 25px;
		}
	}
}
