.block-map {
	position: relative;
	display: flex;
	flex-direction: column;

	@include media-min($medium) {
		justify-content: flex-end;
		align-items: flex-start;
		min-height: 530px;
	}

	@include media-min($large) {
		display: block;
		min-height: 0;
	}

	&__google-map {
		height: 78vw;
		max-height: 300px;

		@include media-min($medium) {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			height: auto;
			max-height: none;
		}
	}

	&__content-container {
		position: relative;

		@include media-min($medium) {
			display: flex;
			width: 100%;
			max-width: $global-width;
			padding: 4rem map-get($row-padding, "medium");
		}

		@include media-min($large) {
			padding: 11rem map-get($row-padding, "large");
			margin: 0 auto;
		}

		&--align-right {
			justify-content: flex-end;
		}
	}

	&__content {
		padding: 4rem map-get($row-padding, "small") 5rem;
		transition: opacity $default-animation-speed $default-transition-easing;

		@include media-min($medium) {
			padding: 5rem 4rem;
			max-width: 400px;
		}

		.block-animate & {
			opacity: 0;
		}
	}
}
