.block-image-side-cta {
	&__container {
		position: relative;

		@include custom-foundation-row-padding(false, ("medium", "large"));

		@include media-min($medium) {
			max-width: $global-width;
			margin: 0 auto;

			display: flex;
			align-items: flex-end;
			padding-top: 5rem;
			padding-bottom: 5rem;
			min-height: 440px;

			&--align-right {
				justify-content: flex-end;
			}
		}

		@include media-min($large) {
			padding-top: 10rem;
			padding-bottom: 10rem;
			min-height: 535px;
		}
	}

	&__image {
		width: 100%;
		height: 56.25vw;

		background-size: cover;
		background-position: center;

		@include media-min($medium) {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			height: 100%;

			&--parallax {
				html.is-desktop & {
					background-attachment: fixed;
				}
			}
		}
	}

	&__content {
		width: 100%;
		margin: 0;
		padding: 2.5rem map-get($row-padding, "small");
		transition: opacity $default-animation-speed $default-transition-easing;

		@include media-min($medium) {
			max-width: 340px;
			padding: 4rem 3.5rem;
		}

		@include media-min($large) {
			max-width: 400px;
			padding: 4.5rem 4rem;
		}

		.block-animate & {
			opacity: 0;
		}
	}
}
