.effect--text-outline {
	color: $color-multnomah-gold;

	@supports (-webkit-text-stroke: 1px $color-multnomah-gold) {
		-webkit-text-stroke: 1px $color-multnomah-gold;
		// -webkit-text-fill-color: transparent;
		//color: transparent;
	}
}

.effect--text-outline-green {
	color: $color-multnomah-green;

	@supports (-webkit-text-stroke: 1px $color-multnomah-green) {
		-webkit-text-stroke: 1px $color-multnomah-green;
		// -webkit-text-fill-color: transparent;
		//color: transparent;
	}
}

.effect--top-line {
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: rem-calc($-zf-bp-value) / 3;
		right: rem-calc($-zf-bp-value) / 3;
		height: 1px;
		background-color: $color-multnomah-gold;

		@include media-min($medium) {
			left: rem-calc($-zf-bp-value) / 2;
			right: rem-calc($-zf-bp-value) / 2;
		}
	}

	@include media-min($large) {
		&-extend-right,
		&-extend-left {
			&::before {
				width: 100vw;
				transition: transform $default-animation-speed $default-transition-easing;
			}
		}

		&-extend-right {
			&::before {
				right: auto;

				.block-animate & {
					transform: translateX(100%);
				}
			}
		}

		&-extend-left {
			&::before {
				left: auto;

				.block-animate & {
					transform: translateX(-100%);
				}
			}
		}
	}
}
