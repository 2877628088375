.block-content-slider {
	.swiper-container {
		height: 100%;
	}

	&__slide-container {
		position: relative;
		display: flex;
		flex-direction: column;
		height: 100%;

		@include media-min($medium) {
			justify-content: flex-end;
			min-height: 470px;
		}
	}

	&__slide-image-container {
		position: relative;
		height: 0;
		padding-top: 78vw;

		@include media-min($medium) {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			height: auto;
			padding-top: 0;
		}
	}

	&__slide-image {
		@include image-constrain("cover");
	}

	&__slide-content {
		position: relative;
		padding: 2.5rem 2rem;
		background-color: $color-multnomah-green;
		height: 100%;

		@include media-min($medium) {
			padding: 4rem 3.5rem;
			width: 100%;
			max-width: 50%;
			background-color: rgba($color-multnomah-green, 0.85);

			position: absolute;
			top: 0;
			bottom: 0;

			.block-content-slider__slide-container--align-right & {
				right: 0;
			}

			.block-content-slider__slide-container--align-left & {
				left: 0;
			}

			p {
				font-size: 1.6rem;
			}
		}

		@include media-min($large) {
			padding: 4.5rem 4rem;
			max-width: 400px;
		}
	}

	&__nav-container {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 2.5rem;
	}

	&__nav-button {
		position: relative;
		width: 46px;
		height: 46px;
		margin: 0 1rem;
		border: 1px solid $color-multnomah-gold;
		border-radius: 100%;
		cursor: pointer;

		&::after {
			content: "";
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 0;
			height: 0;
			border: 14px solid $color-white;
			border-top-color: transparent;
			border-bottom-color: transparent;
			border-top-width: 8px;
			border-bottom-width: 8px;
		}

		&--prev {
			&::after {
				left: -1px;
				border-left-color: transparent;
			}
		}

		&--next {
			&::after {
				left: 17px;
				border-right-color: transparent;
			}
		}
	}
}
