$form-disabled-opacity: 0.4;

$input-border: 1px solid $color-multnomah-gold;
$input-text-color: $body-font-color;

$input-checkbox-radio-size: 16px;
$input-checked-fill-color: $color-black;

label {
	font: normal normal $font-weight-medium 1.2rem / 1.5 $font-poppins;
	font-size: inherit;
	color: rgba($color-black, 0.85);
	letter-spacing: 0.2px;
}

$input-style-selectors: (
	"input[type=text]",
	"input[type=number]",
	"input[type=tel]",
	"input[type=url]",
	"input[type=email]",
	"input[type=password]",
	"input[type=search]",
	"textarea",
	"select"
);

%default-input-styles {
	transition: all $default-transition-speed ease-out;
	appearance: none;
	border-radius: $default-input-border-radius;
	border: $input-border;
	// padding: 8px 20px 8px 18px;
	padding: 0 20px 0 18px;
	background-color: $color-white;
	min-height: $default-input-min-height;
	font: $body-font;
	font-size: inherit;
	color: $input-text-color;
	display: block;
	width: 100%;

	&::placeholder {
		color: rgba($color-black, 0.85);
	}

	@include media-min($medium) {
		display: inline-block;
		// padding: 10px 20px 12px 18px;
	}

	@include media-min($large) {
		// padding: 0.8rem 2rem;
		padding: 0 2rem;
	}

	&:focus {
		// border-radius: $default-input-border-radius;
		// border: 1px solid $color-multnomah-gold;
		color: $body-font-color;
	}

	&:disabled {
		opacity: $form-disabled-opacity;
	}
}

@include classes-from-list($input-style-selectors) {
	@extend %default-input-styles;
}

input[type="text"],
input[type="email"] {
	&.arrow-right {
		background-image: url("#{$theme-url-base}/media/images/arrow-right.png");
		background-repeat: no-repeat;
		background-position: calc(100% - 15px) 50%;
		padding-right: 35px;
	}
}

select,
select:focus {
	background-image: url("#{$theme-url-base}/media/images/arrow-down.png");
	background-repeat: no-repeat;
	background-position: calc(100% - 20px) 50%;
	padding-right: 50px;
	font: normal normal $font-weight-regular 1.4rem / 1.4 $font-poppins;
	color: rgba($color-black, 0.85);

	&:disabled {
		opacity: $form-disabled-opacity;
	}

	&[multiple] {
		background-image: none;
	}
}

select::-ms-expand {
	display: none;
}

select option {
	color: rgba($color-black, 0.85);
}

input[type="checkbox"],
input[type="radio"] {
	display: none;

	& + label {
		cursor: pointer;
		position: relative;
		padding-left: 25px;
		display: inline-block;
		line-height: 1.2;

		&::before,
		&::after {
			content: "";
			position: absolute;
		}

		&::before {
			width: $input-checkbox-radio-size;
			height: $input-checkbox-radio-size;
			// border: 1px solid $color-black-25;
			border: $input-border;
			background: transparent;
		}

		&::after {
			opacity: 0;
			transition: opacity $default-transition-speed ease-in;
		}

		& + input[type="checkbox"],
		& + input[type="radio"] {
			& + label {
				margin-left: 2rem;
			}
		}
	}

	&:checked + label {
		&::after {
			opacity: 1;
		}
	}

	&:disabled + label {
		opacity: $form-disabled-opacity;
	}
}

// fix for Form Assembly not displaying matrix with radio correctly
.matrixLayout  {
	input[type="checkbox"],
	input[type="radio"] {
		display: inline-block;
	}
}

input[type="checkbox"] {
	& + label {
		&::before {
			transform: translateY(-50%);
			top: 50%;
			left: 0;
			border-radius: 3px;
		}

		&::after {
			transform: translateY(-50%);
			top: 50%;
			left: 3px;
			// width: #{$input-checkbox-radio-size - 8px};
			width: 10px;
			// height: #{$input-checkbox-radio-size - 8px};
			height: 8px;
			// background: $input-checked-fill-color;
			background-image: url("#{$theme-url-base}/media/images/check-blue.png");
		}
	}
}

input[type="radio"] {
	& + label {
		&::before {
			transform: translateY(-50%);
			top: 50%;
			left: 0;

			border-radius: 100%;
		}

		&::after {
			transform: translateY(-50%);
			top: 50%;
			left: 5px;
			width: #{$input-checkbox-radio-size - 10px};
			height: #{$input-checkbox-radio-size - 10px};
			border-radius: 100%;
			background: $input-checked-fill-color;
		}
	}
}

@include dark-bg("", "") {
	@include classes-from-list($input-style-selectors) {
		border-color: $color-white;
		background-color: $color-white;
		color: $input-text-color;
	}

	input[type="checkbox"] {
		& + label {
			&::after {
				background-image: url("#{$theme-url-base}/media/images/check-white.png");
			}
		}
	}

	input[type="radio"] {
		& + label {
			&::after {
				background: $color-white;
			}
		}
	}
}

.form-field-with-icon {
	display: flex;
	align-items: center;

	border-radius: $default-input-border-radius;
	border: $input-border;
	padding-right: 18px;
	background-color: $color-white;
	min-height: $default-input-min-height;

	input {
		border: 0;
		margin-right: 1.5rem;
		outline: 0;
	}

	&__icon {
		color: $color-multnomah-gold;
		width: 18px;
		height: 18px;
	}

	&--icon-left {
		flex-direction: row-reverse;

		padding-right: 0;
		padding-left: 18px;

		input {
			margin-right: 0;
		}
	}
}

.eb-filter-container input[type=checkbox]{
	display: inline-block
}
