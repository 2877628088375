.block-calltoaction {
	overflow-x: hidden;

	&__inner {
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: $global-width;
		margin: 0 auto;

		@include media-min($large) {
			@include custom-foundation-row-padding(false, ("large"));

			flex-direction: row;
		}

		&--no-side-content {
			justify-content: center;

			@include media-min($large) {
				.block-calltoaction__container {
					max-width: 830px;
				}
			}
		}

		@include media-min($large) {
			&--side-content {
				.block-calltoaction__container--main {
					flex-basis: 45%;
					padding-right: 4rem;
				}

				.block-calltoaction__container--side {
					flex-basis: 55%;
				}
			}
		}
	}

	&__container {
		padding-top: map-deep-get($block-padding, "small", "top");
		padding-bottom: map-deep-get($block-padding, "small", "bottom");

		@include custom-foundation-row-padding(false, ("small", "medium"));

		@include media-min($medium) {
			padding-top: map-deep-get($block-padding, "medium", "top");
			padding-bottom: map-deep-get($block-padding, "medium", "bottom");
		}

		@include media-min($large) {
			padding-top: map-deep-get($block-padding, "large", "top");
			padding-bottom: map-deep-get($block-padding, "large", "bottom");
			padding-left: 0;
			padding-right: 0;
		}

		&--main {
			transition: opacity $default-animation-speed $default-transition-easing;

			.block-animate & {
				opacity: 0;
			}
		}

		&--side {
			position: relative;
			clip-path: polygon(0% 0%, 50% 8%, 100% 0%, 100% 100%, 0% 100%);
			transition: clip-path $default-animation-speed $default-transition-easing;

			.block-animate & {
				clip-path: polygon(0% 0%, 50% 0%, 100% 0%, 100% 100%, 0% 100%);
			}

			@include media-min($large) {
				clip-path: polygon(0% 0%, 150% 0, 150% 100%, 0% 100%, 8% 50%);

				.block-animate & {
					clip-path: polygon(0% 0%, 150% 0, 150% 100%, 0% 100%, 0% 50%);
				}
			}

			@include media-min($xlarge) {
				clip-path: polygon(0% 0%, 500% 0, 500% 100%, 0% 100%, 5% 50%);

				.block-animate & {
					clip-path: polygon(0% 0%, 500% 0, 500% 100%, 0% 100%, 0% 50%);
				}
			}
		}

		&--side-image {
			padding: 0;

			@include media-min($large) {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 50vw;
				width: 50vw;
			}
		}

		&--side-links {
			@include media-min($large) {
				padding-left: 12rem;
			}

			&::after {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				width: 50vw;
				transform: translateX(100%);
				background-color: $color-white;
			}

			&.bg-gray {
				&::after {
					background-color: $color-off-white;
				}
			}

			&.bg-green {
				&::after {
					background-color: $color-multnomah-green;
				}
			}

			&.bg-dark-green {
				&::after {
					background-color: $color-dark-green;
				}
			}
		}

		&--text-center {
			text-align: center;
		}
	}

	&__buttons {
		display: flex;
		flex-direction: column;
		margin-top: 4rem;

		@include media-min($medium) {
			flex-direction: row;
			margin-top: 3rem;

			.block-calltoaction__container--text-center & {
				justify-content: center;
			}
		}

		.button + .button {
			margin-top: 2rem;

			@include media-min($medium) {
				margin-top: 0;
				margin-left: 2rem;
			}
		}
	}

	&__side-image-container {
		@include media-min($large) {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
	}

	&__side-image {
		@include media-min($large) {
			@include image-constrain("cover");
		}
	}

	&__side-links-title {
		margin-top: 2.5rem;

		@include media-min($large) {
			margin-top: 2.5rem;
		}
	}

	&__side-links-columns {
		margin-top: 3rem;

		@include media-min($medium) {
			column-count: 2;
		}
	}

	&__side-link {
		display: flex;
		align-items: center;
		font: normal normal $font-weight-medium 1.8rem / 1.2 $font-poppins;
		letter-spacing: 0.3px;
		text-decoration: none;

		& + & {
			margin-top: 2.5rem;
		}
	}

	&__side-link-icon {
		width: 15px;
		height: 15px;
		color: $color-multnomah-gold;
		margin-left: 2rem;
	}
}
