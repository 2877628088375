.team-member {
	display: flex;
	flex-direction: column;
	align-items: center;

	&__image-container {
		position: relative;
		width: 100%;
		height: 0;
		padding-top: 100%;
		border-radius: 100%;
		border: 1px solid $color-multnomah-gold;
		overflow: hidden;
	}

	&__image {
		@include image-constrain("cover");
	}

	&__name {
		margin-top: 2.5rem;

		.team-member--no-image & {
			margin-top: 0;
		}
	}

	&__email {
		margin-top: 1rem;
	}

	&__email-part {
		display: inline-block;

		&:nth-child(2) {
			margin-left: -4px;
		}
	}

	// expanding the selector to overcome a specifity issue for the 'color' property
	.team-member__name-link,
	.team-member__email,
	.team-member__phone-link {
		text-decoration: none;
		transition: color $default-transition-speed $default-transition-easing;

		&:hover {
			color: $color-multnomah-gold;
		}
	}

	&__email,
	&__phone-link {
		font-weight: $font-weight-semibold;
	}

	&__title {
		margin-top: 0.5rem;
		color: rgba($color-black, 0.85);

		.bg-green &,
		.bg-dark-green & {
			color: $color-white;
		}
	}

	&--no-image {
		.team-member__email,
		.team-member__phone-link {
			text-decoration: none;
			// font-weight: $font-weight-semibold;
			color: $color-link-light-bg;
			transition: color $default-transition-speed ease-in-out;

			&:hover,
			&:active {
				color: $color-multnomah-gold;
			}
		}
	}
}
