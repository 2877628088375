.block-media-content {
	&__col-content {
		position: relative;
		padding-top: 2rem;
	}

	&__media-container {
		position: relative;
		margin-top: 3rem;

		@include media-min($medium) {
			margin-top: 5rem;
		}

		@include media-min($large) {
			margin-top: 0;
		}
	}

	&__col-content,
	&__media-container {
		transition: opacity $default-animation-speed $default-transition-easing;
	}

	&.block-animate {
		.block-media-content__col-content,
		.block-media-content__media-container {
			opacity: 0;
		}
	}

	@include media-min($large) {
		.media-placement--left {
			.block-media-content__col-content {
				padding-left: 4rem;
			}

			.effect--top-line {
				&::before {
					left: calc(#{rem-calc($-zf-bp-value) / 2} + 2rem);
				}
			}
		}

		.media-placement--right {
			.block-media-content__media-container {
				margin-left: 4rem;
			}
		}
	}
}
