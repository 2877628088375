.block-stats {
	.block-container + & .block-inner {
		@include media-max($medium) {
			padding-top: 5rem !important;
		}
	}

	&__columns-container {
		margin-top: -5rem;
	}

	&__column {
		margin-top: 5rem;
	}

	&__featured-text,
	&__description {
		display: block;
	}

	&__featured-text {
		font-size: 7.6rem;
		font-weight: $font-weight-black;
		line-height: 1;
		letter-spacing: 0.5px;

		/* prettier-ignore */
		transition: opacity #{$default-animation-speed-unitless / 2}s $default-transition-easing;

		.block-animate & {
			opacity: 0;
		}
	}

	&__description {
		margin-top: 1rem;
		font: normal normal $font-weight-bold 1.8rem / 1.3 $font-poppins;
		letter-spacing: 0.4px;

		/* prettier-ignore */
		transition: opacity #{$default-animation-speed-unitless / 2}s $default-transition-easing #{$default-animation-speed-unitless / 2}s;

		.block-animate & {
			opacity: 0;
		}

		.block-bg-none &,
		.bg-white &,
		.bg-gray & {
			color: rgba($color-black, 0.85);
		}
	}
}
