.block-student-life {
	&__title {
		margin-bottom: 3rem;
	}

	&__cards-container {
		margin-top: 0;

		@include media-min($large) {
			margin-top: -3rem;
		}

		.columns {
			margin-top: 2rem;

			@include media-min($medium) {
				margin-top: 3rem;
			}

			@for $i from 0 through 3 {
				&:nth-child(#{$i + 1}) {
					/* prettier-ignore */
					transition: opacity #{$default-animation-speed-unitless / 2}s $default-transition-easing #{($default-animation-speed-unitless / 2) * $i}s;
				}
			}

			.block-animate & {
				opacity: 0;
			}
		}

		.card-default__title {
			font-size: 2rem;
		}
	}

	@include media-min($large) {
		.card-default__image-container--slim {
			height: 190px;
		}
	}
}
