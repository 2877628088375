@mixin object-fit-polyfill($object-fit, $object-position: "") {
	@if $object-position != "" {
		object-fit: #{$object-fit};
		object-position: #{$object-position};
		font-family: "object-fit: #{$object-fit}; object-position: #{$object-position};";
	} @else {
		object-fit: #{$object-fit};
		font-family: "object-fit: #{$object-fit}";
	}
}

@mixin image-constrain($object-fit, $object-position: "") {
	@include object-fit-polyfill($object-fit, $object-position);

	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@mixin classes-from-list($list, $prepend: "", $append: "") {
	#{generate-selector-string($list, $prepend, $append)} {
		@content;
	}
}

@mixin dark-bg($prepend: "", $append: " &") {
	@include classes-from-list($bg-colors-with-light-text-classes, $prepend, $append) {
		@content;
	}
}

@mixin stacked-column-spacing($spacing-small: 6rem, $spacing-medium: 6rem, $spacing-large: 6rem) {
	.columns.small-12 + .columns {
		margin-top: $spacing-small;
	}

	@include media-min($medium) {
		.columns.small-12 + .columns {
			margin-top: 0;
		}

		.columns.medium-12 + .columns {
			margin-top: $spacing-medium;
		}
	}

	@include media-min($large) {
		.columns.medium-12 + .columns {
			margin-top: 0;
		}

		.columns.large-12 + .columns {
			margin-top: $spacing-large;
		}
	}
}

@mixin media($args...) {
	$media-type: "only screen";
	$media-type-key: "media-type";
	$args: keywords($args);
	$expr: "";

	@if map-has-key($args, $media-type-key) {
		$media-type: map-get($args, $media-type-key);
		$args: map-remove($args, $media-type-key);
	}

	@each $key, $value in $args {
		@if $value {
			$expr: "#{$expr} and (#{$key}: #{$value})";
		}
	}

	@media #{$media-type} #{$expr} {
		@content;
	}
}

@mixin media-max($max) {
	@include media(
		$max-width: (
			$max - 1,
		)
	) {
		@content;
	}
}

@mixin media-min($min) {
	@include media($min-width: $min) {
		@content;
	}
}

@mixin custom-foundation-row-padding-bp($bp, $include_col_gutter: true) {
	$padding: 0;

	@if $include_col_gutter {
		$padding: rem-calc(map-get($grid-column-gutter, $bp)) / 2;
	}

	padding-right: map-get($row-padding, $bp) - $padding;
	padding-left: map-get($row-padding, $bp) - $padding;
}

@mixin custom-foundation-row-padding(
	$include_col_gutter: true,
	$sizes: (
		"small",
		"medium",
		"large",
	)
) {
	@if index($sizes, "small") != null {
		@include custom-foundation-row-padding-bp("small", $include_col_gutter);
	}

	@if index($sizes, "medium") != null {
		@include media-min($medium) {
			@include custom-foundation-row-padding-bp("medium", $include_col_gutter);
		}
	}

	@if index($sizes, "large") != null {
		@include media-min($large) {
			@include custom-foundation-row-padding-bp("large", $include_col_gutter);
		}
	}
}

@mixin image-cover-entire-container($object-fit: "cover", $object-position: "") {
	@include object-fit-polyfill($object-fit, $object-position);

	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: #{$object-fit};

	@if $object-position != "" {
		object-position: #{$object-position};
	}
}
