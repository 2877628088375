.video-button {
	position: absolute;
	left: 0;
	bottom: 2rem;

	@include media-min($medium) {
		bottom: 4rem;
	}

	background-color: $color-multnomah-gold;
	color: $color-white;

	&__icon {
		margin: 0.7rem 0.7rem 0;
		width: 30px;
		height: 30px;

		@include media-min($medium) {
			margin: 1.2rem 1.2rem 0.4rem;
			width: 46px;
			height: 46px;
		}
	}
}
