.block-testimonial {
	&__container {
		position: relative;

		@include media-min($medium) {
			@include custom-foundation-row-padding(false, ("medium", "large"));

			max-width: $global-width;
			margin: 0 auto;

			display: flex;
			align-items: flex-end;
			padding-top: 2rem;
			padding-bottom: 2rem;
			min-height: 440px;

			&--align-right {
				justify-content: flex-end;
			}
		}

		@include media-min($large) {
			padding-top: 6rem;
			padding-bottom: 6rem;
			min-height: 550px;
		}
	}

	&__image {
		height: 56.25vw;
		background-size: cover;
		background-position: center;

		@include media-min($medium) {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;

			&--parallax {
				html.is-desktop & {
					background-attachment: fixed;
				}
			}
		}
	}

	&__blockquote {
		width: 100%;
		margin: 0;
		transition: opacity $default-animation-speed $default-transition-easing;

		@include media-min($medium) {
			max-width: 340px;
		}

		@include media-min($large) {
			max-width: 480px;
		}

		.block-animate & {
			opacity: 0;
		}
	}
}
