body.hellobar--position-top {
	transition: margin 0.5s ease-in-out;
}

.hellobar {
	z-index: 10;
	position: fixed;
	left: 0;
	width: 100%;
	opacity: 0;
	pointer-events: none;
	transition: all 0.5s ease-in-out;
	background: rgba($color-multnomah-green, 0.95);
	font-size: 1.6rem;

	&-bg-gold {
		background: rgba($color-multnomah-gold, 0.95);
	}

	&-bg-dark-green {
		background: rgba($color-dark-green, 0.95);
	}

	body.hellobar--position-top & {
		top: 0;
		transform: translateY(-100%);
	}

	body.hellobar--position-bottom & {
		bottom: 0;
		transform: translateY(100%);
	}

	body.hellobar--visible & {
		opacity: 1;
		pointer-events: all;
		transform: translateY(0);
	}

	&__wrapper {
		@include custom-foundation-row-padding(false);

		padding-top: 2rem;
		padding-bottom: 2rem;
		max-width: $global-width;
		margin: 0 auto;
		display: flex;

		@include media-min($medium) {
			align-items: center;
		}
	}

	&__text-wrapper {
		width: 100%;

		@include media-min($medium) {
			display: flex;
			align-items: center;
		}
	}

	&__text-content {
		color: $color-white;
	}

	&__close-wrapper {
		margin-left: 6rem;
	}

	&__close {
		cursor: pointer;
	}

	&__close-icon {
		width: 14px;
		height: 14px;
		color: $color-white;
	}

	&__link {
		display: flex;
		align-items: center;
		color: $color-white;
		font-weight: $font-weight-bold;
		text-decoration: none;
		margin-top: 0.5rem;

		@include media-min($medium) {
			margin-top: 0;
			margin-left: 2.5rem;
		}
	}

	&__link-icon {
		width: 15px;
		height: 15px;
		margin-left: 1.5rem;
	}
}
