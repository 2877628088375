.tmpl-hierarchy-sidebar {
	&__sidebar {
		z-index: 1;
		position: sticky;
		top: 2rem;
		margin-bottom: 3.5rem;
		transition: top ($default-transition-speed * 2) $default-transition-easing;

		html.scroll-up & {
			top: 7.5rem;

			@include media-min($medium) {
				top: 10rem;
			}

			@include media-min($large) {
				top: 0;
			}
		}

		@include media-min($large) {
			position: relative;
			top: 0;
			margin-bottom: 0;
		}
	}

	&__sidebar-header {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 2rem 2.5rem;
	}

	&__sidebar-header-toggle {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		outline: 0;
		cursor: pointer;

		@include media-min($large) {
			display: none;
		}
	}

	&__sidebar-body {
		max-height: 0;
		pointer-events: none;
		transition: all $default-transition-speed cubic-bezier(0, 1, 0, 1);
		overflow: hidden;
		padding: 0 0 0 1.5rem;

		@include media-min($large) {
			max-height: none;
			pointer-events: all;
			padding: 2rem 0 2.5rem 2.5rem;
		}

		@include media-max($large) {
			.sidebar-expanded & {
				max-height: 9999px;
				pointer-events: all;
				transition: all $default-transition-speed cubic-bezier(1, 0, 1, 0);
				padding: 1.5rem 0 1.5rem 1.5rem;
			}
		}
	}

	&__body-title {
		font-size: 3.6rem;
		text-align: center;

		@include media-min($medium) {
			font-size: 4.2rem;
		}

		@include media-min($large) {
			font-size: 5rem;
			text-align: left;
		}
	}

	&__content-row {
		position: relative;
		margin-top: 4.5rem;
	}

	&__top-content {
		position: relative;
		padding-top: 2rem;
	}

	// &__sidebar-toggle-button {
	// 	margin: 0;
	// 	outline: 0;

	// 	@include media-min($large) {
	// 		display: none;
	// 	}
	// }

	&__sidebar-toggle-icon {
		width: 14px;
		height: 14px;
		margin: 0;
		color: $color-multnomah-gold;
		transition: transform $default-transition-speed $default-transition-easing;

		.sidebar-expanded & {
			transform: rotate(180deg);
		}

		@include media-min($large) {
			display: none;
		}
	}
}
