@each $bg_color_name, $color_values in $bg-colors {
	.bg-#{$bg_color_name} {
		background-color: map-get($color_values, "background");
		background: map-get($color_values, "background");
		color: map-get($color_values, "text");

		label,
		p,
		li,
		blockquote,
		a:not(.button),
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: inherit;
		}

		@if (map-has-key($color_values, "elements")) {
			@each $element, $element_color in map-get($color_values, "elements") {
				#{$element} {
					color: $element_color;
				}
			}
		}
	}
}

.block-bg-image {
	background-color: $color-white;
	background-size: cover;
	color: $color-white;

	&--dark-text {
		color: $text-dark;
	}

	label,
	p,
	li,
	blockquote,
	a:not(.button),
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: inherit;
	}

	&.block-bg-overlay {
		&::before {
			z-index: -1;
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: rgba($color-dark-green, 0.85);
		}

		.block-inner {
			z-index: 1;
			position: relative;
		}
	}
}
