/**
 *	Set base font size to 10px
 *	This makes calculating rem values very easy
 *	Just divide your pixel value by 10
 *	Need an rem value that matches 13px? 1.3rem
 *	Need an rem value that matches 27px? 2.7rem
 */
:root {
	font-size: 10px;
}

/**
 *	Reset element margins
 */
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li {
	margin: 0;
}

@each $el, $margin-top in $next-el-margin-top {
	#{$el} + * {
		margin-top: $margin-top;
	}
}

@each $el, $margin-top in $following-el-margin-top {
	* + #{$el} {
		margin-top: $margin-top;
	}
}

/*
 *	Global element styling
 */
body {
	font: $body-font;
	color: $body-font-color;

	@include media-min($medium) {
		font-size: $body-font-size-medium;
	}
}

p {
	font: $body-font;
	font-size: inherit;
	color: $body-font-color;
}

li {
	font: $body-font;
	font-size: inherit;
	color: $body-font-color;
}

blockquote {
	font: $body-font;
	font-size: inherit;
	color: $body-font-color;
}

h1,
%h1 {
	font: normal normal $font-weight-black 3.2rem / 1.28 $font-masqualero;
	color: $color-multnomah-green;
	text-transform: none;
	//letter-spacing: 1.3px;

	@include media-min($medium) {
		font-size: 7rem;
		line-height: 1.1;
		letter-spacing: 0px;
	}
}

h2,
%h2 {
	font: normal normal $font-weight-black 3.6rem / 1.1 $font-masqualero;
	color: $color-h2-green;
	//letter-spacing: 1.6px;
	text-transform: none;

	@include media-min($medium) {
		font-size: 5.6rem;
		line-height: 1.15;
		letter-spacing: 0px;
	}
}

h3,
%h3 {
	font: normal normal $font-weight-extra-bold 2.8rem / 1.28 $font-masqualero;
	color: $color-black;
	//letter-spacing: 0.6px;

	@include media-min($medium) {
		font-size: 3.8rem;
		line-height: 1.21;
		//letter-spacing: 1px;
	}
}

h4,
%h4 {
	font: normal normal $font-weight-bold 2.2rem / 1.3 $font-poppins;
	color: $color-black;
	//letter-spacing: 0.34px;

	@include media-min($medium) {
		font-size: 2.6rem;
		line-height: 1.4;
		//letter-spacing: 0.4px;
	}

	@include media-min($large) {
		//letter-spacing: 0.5px;
	}
}

h5,
%h5 {
	font: normal normal $font-weight-bold 2.2rem / 1.36 $font-poppins;
	color: $color-black;
}

h6,
%h6 {
	font: normal normal $font-weight-medium 2rem / 1.4 $font-poppins;
	color: $color-black;
}

/**
 *	Text size modifiers - usually used with paragaph elements
 */
.large,
%large-text {
	font-size: #{$body-font-size + 0.2rem};

	@include media-min($medium) {
		font-size: #{$body-font-size-medium + 0.2rem};
	}
}

small,
.small,
%small-text {
	font-size: 1.6rem;
}
