.image-link {
	position: relative;
	display: block;
	height: 0;
	padding-top: 54%;
	color: $white;
	text-decoration: none;

	&--no-image,
	&--no-ratio {
		height: auto;
		padding-top: 0;

		.image-link__text-container {
			position: relative;
			height: 100%;
		}
	}

	&__image {
		@include image-constrain("cover");
	}

	&__text-container {
		position: absolute;
		bottom: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		background-color: rgba($color-multnomah-green, 0.85);
		padding: 2rem;
		transition: background-color $default-transition-speed $default-transition-easing;

		&--align-top {
			justify-content: flex-start;
		}
	}

	&:not(.image-link--large) {
		.image-link__text-container {
			top: 0;
			right: 0;
		}
	}

	&__title {
		font: normal normal $font-weight-bold 1.8rem / 1.16 $font-poppins;
		//letter-spacing: 0.4px;
		color: $color-white;
	}

	&__content,
	&__link-text {
		font: normal normal $font-weight-regular 1.6rem / 1.375 $font-poppins;
		letter-spacing: 0.3px;
	}

	&__content {
		margin-top: 0.5rem;
		color: $color-white;
	}

	&__link-text {
		margin-top: 0.5rem;
		font-weight: $font-weight-semibold;
		color: $color-multnomah-gold;
		transition: color $default-transition-speed $default-transition-easing;
	}

	&--large {
		padding-top: 41%;

		.image-link__text-container {
			padding: 3rem;
			max-width: 50%;
		}
	}

	&:hover,
	&.active {
		.image-link__text-container {
			background-color: $color-multnomah-gold;
		}

		.image-link__link-text {
			color: $color-white;
		}
	}
}
