.wysiwyg {
	a:not(.button) {
		text-decoration: none;
		font-weight: $font-weight-semibold;
		color: $color-link-light-bg;
		transition: color $default-transition-speed ease-in-out;

		&:hover,
		&:active {
			color: $color-multnomah-gold;
		}
	}

	ul,
	ol {
		padding-left: 3.5rem;

		li {
			margin: 0 0 0.5rem 0;

			ul,
			ol {
				margin-top: 0.5rem;
			}

			li {
				margin-left: 1.5rem;
			}
		}
	}

	ul {
		list-style: none;

		li {
			position: relative;

			&::before {
				content: "";
				position: absolute;
				top: 13px;
				left: -2rem;
				width: 4px;
				height: 4px;
				border-radius: 100%;
				background-color: $text-dark;
			}
		}
	}

	strong {
		color: rgba($color-black, 0.85);
	}

	table + * {
		margin-top: 2rem;
	}
}

@include dark-bg("", "") {
	&.wysiwyg,
	.wysiwyg {
		a:not(.button) {
			color: $color-link-dark-bg;
		}

		strong {
			color: $color-white;
		}

		ul li::before {
			background-color: $text-light;
		}

		table {
			background-color: $color-white;
			color: $text-dark;
		}
	}
}

.block-bg-image {
	&.wysiwyg,
	.wysiwyg {
		a:not(.button) {
			color: $color-multnomah-gold;
		}
	}
}
