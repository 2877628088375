.ui__spin-loader {
	display: block;
	margin: 0 auto;
	font-size: 10px;
	position: relative;
	text-indent: -9999em;
	border-top: 1.1em solid rgba(transparent, 0.05);
	border-right: 1.1em solid rgba(transparent, 0.05);
	border-bottom: 1.1em solid rgba(transparent, 0.05);
	border-left: 1.1em solid $color-green;
	transform: translateZ(0);
	animation: spin-loader 1s infinite linear;

	&,
	&::after {
		border-radius: 50%;
		width: 50px;
		height: 50px;
	}

	.bg-black & {
		border-left: 1.1em solid $color-white;
	}
}

@keyframes spin-loader {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
