.archive__load-more-container {
	@include custom-foundation-row-padding(false);

	display: flex;
	justify-content: center;
	margin-top: 2rem;

	.button {
		width: 100%;
	}

	@include media-min($medium) {
		margin-top: 6rem;
	}
}
