.accordion {
	width: 100%;

	&__section-title {
		display: flex;
		align-items: center;
		width: 100%;
		padding: 1.5rem 0;
		cursor: pointer;

		&::before,
		&::after {
			display: none;
		}

		// default form styling overrides
		input[type="checkbox"] + &,
		input[type="radio"] + & {
			display: flex;
			padding-left: 0;
		}

		input:checked ~ & {
			.accordion__section-title-icon {
				transform: rotate(45deg);
			}
		}
	}

	&__section-title-text {
		font: normal normal $font-weight-medium 1.8rem / 1.25 $font-poppins;
		//letter-spacing: 0.45px;

		@include media-min($medium) {
			font-size: 2rem;
		}
	}

	&__section-title-icon {
		width: 26px;
		height: 26px;
		min-width: 26px;
		min-height: 26px;
		color: $color-multnomah-gold;
		transition: transform $default-transition-speed $default-transition-easing;
		margin-right: 1.2rem;
	}

	&__section-content {
		position: relative;
		transition: all 0.3s cubic-bezier(0, 1, 0, 1);
		max-height: 0;
		overflow: hidden;
		pointer-events: none;
		padding-left: 3.7rem;

		p,
		li {
			font-size: 1.6rem;
		}

		ul li::before {
			top: 10px;
		}

		// content is visible
		input:checked ~ & {
			transition: all 0.3s cubic-bezier(1, 0, 1, 0);
			max-height: 9999px;
			pointer-events: all;
			padding-bottom: 2rem;
		}
	}
}
