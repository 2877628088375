.banner-default {
	position: relative;

	&__inner {
		padding-top: 3rem;
		padding-bottom: 4.5rem;

		@include media-min($medium) {
			padding-top: 6rem;
			padding-bottom: 6rem;
		}

		@include media-min($large) {
			padding-top: 6rem;
			padding-bottom: 6rem;
		}
	}

	&__column-side {
		margin-top: 4rem;

		@include media-min($medium) {
			margin-top: 5rem;
		}

		@include media-min($large) {
			margin-top: 0;

			.image-link {
				margin-left: 4rem;
			}
		}
	}

	&__breadcrumbs + &__title {
		margin-top: 1.5rem;
	}

	&__icon-links {
		margin-top: 3rem;

		.icon-link + .icon-link {
			margin-top: 2.5rem;
		}
	}

	&__buttons {
		display: flex;
		flex-direction: column;
		margin-top: 1.5rem;

		@include media-min($medium) {
			flex-direction: row;
			margin-top: 3rem;
		}

		.button {
			margin-top: 1.5rem;

			@include media-min($medium) {
				margin-top: 0;

				& + .button {
					margin-left: 2rem;
				}
			}
		}

		&--spread {
			@include media-min($medium) {
				.button {
					width: 100%;
				}
			}
		}
	}

	&__sticky-cta {
		text-align: center;
		padding: 3rem 3.5rem;

		* + .button {
			margin-top: 2.5rem;
		}

		@include media-min($medium) {
			padding: 5rem 6.5rem;
		}

		@include media-min($large) {
			position: sticky;
			top: 16rem;
			transition: top ($default-transition-speed * 2) $default-transition-easing;

			html.scroll-down & {
				top: 4rem;
			}
		}
	}
}
