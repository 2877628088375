.block-video {
  .block-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: map-deep-get($block-padding, "small", "top");
    @include media-min($medium) {
			padding-top: map-deep-get($block-padding, "medium", "top");
			padding-bottom: map-deep-get($block-padding, "medium", "bottom");
		}

		@include media-min($large) {
			padding-top: map-deep-get($block-padding, "large", "top");
			padding-bottom: map-deep-get($block-padding, "large", "bottom");
		}
  }

  &__outer-container {
    min-width: 400px;
    max-height: 585px;
    max-width: 1040px;
    overflow: hidden;
  }

  &__video-button-container {
    position: absolute;
    transform: translate(-50%, -50%);
    top: calc(50% - 50px);
    left: 50%;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 3%;

    svg {
      color: #d69919;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 75%;
    }
  }

  &__image-container {
    transition: all .3s ease-in-out;

    &:hover {
      .block-video__video-button-container {
        padding: 4%;

        svg {
          width: 80%;
        }
      }
    }
  }
}