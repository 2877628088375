.card-blog {
	position: relative;
	display: block;
	height: 100%;
	background-color: $color-white;
	border: 1px solid $color-multnomah-gold;
	border-radius: $default-border-radius;
	text-decoration: none;
	color: $text-dark;
	transition: all $default-transition-speed $default-transition-easing;
	overflow-y: hidden;

	@include media-min($medium) {
		&--featured {
			display: flex;
			justify-content: flex-end;
		}
	}

	&--simple {
		border: 0;
		background-color: transparent;
		height: auto;

		.card-blog__content {
			background-color: transparent;
		}

		&:hover {
			background-color: transparent;
			color: $text-dark;

			.card-blog__category,
			.card-blog__title {
				color: $color-multnomah-gold;
			}

			.card-blog__content {
				color: $text-dark;
			}
		}
	}

	&__image-container {
		position: relative;
		height: 80vw;
		max-height: 230px;

		@include media-min($medium) {
			height: 32vw;

			.card-blog--featured & {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				// height: auto;
				height: 100%;
				max-height: none;
			}
		}

		@include media-min($large) {
			height: 0;
			max-height: none;

			padding-top: 56.25%;

			.card-blog--featured & {
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	&__image {
		@include image-cover-entire-container;
	}

	&__category {
		font: normal normal $font-weight-bold 1.8rem / 1.1 $font-poppins;
		letter-spacing: 0.3px;
		color: $color-multnomah-gold;
	}

	&__title {
		margin-top: 1rem;
	}

	p {
		font-size: 1.6rem;
	}

	&__category,
	&__title,
	p {
		transition: color $default-transition-speed $default-transition-easing;
	}

	&__content {
		padding: 2.5rem 1.8rem;
		background-color: $color-white;
		transition: background-color $default-transition-speed $default-transition-easing;

		@include media-min($medium) {
			padding: 2.5rem;

			.card-blog--featured & {
				position: relative;
				margin-top: 165px;
				max-width: 47.5%;
			}
		}

		@include media-min($large) {
			.card-blog--featured & {
				margin-top: 26.75%;
			}
		}
	}

	&:not(.card-blog--simple):hover {
		background-color: $color-multnomah-gold;
		color: $color-white;

		.card-blog__category,
		.card-blog__title,
		p {
			color: $color-white;
		}

		.card-blog__content {
			background-color: $color-multnomah-gold;
		}
	}
}
