.block-image {
	&__image-container {
		position: relative;
		display: block;
		height: 56.25vw;
		max-height: 465px;

		@include media-min($medium) {
			height: 45vw;
		}

		@include media-min($large) {
			height: 33vw;
		}

		&:hover {
			.block-image__video-text {
				opacity: 1;
				transform: translateX(0);
			}
		}
	}

	&__image {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;

		background-size: cover;
		background-position: center;

		@include media-min($medium) {
			html.is-desktop & {
				&--parallax {
					background-attachment: fixed;
				}
			}
		}
	}

	&__video-button-container {
		z-index: 1;
		position: absolute;
		left: 0;
		bottom: 2rem;
		display: flex;
		align-items: center;
		background-color: $color-multnomah-gold;
		color: $color-white;
		width: 50px;
		height: 50px;

		@include media-min($medium) {
			bottom: 4rem;
			width: 70px;
			height: 70px;
		}
	}

	&__video-icon {
		margin: 0 0.7rem;
		min-width: 30px;
		min-height: 30px;

		@include media-min($medium) {
			margin: 0 1.2rem;
			min-width: 46px;
			min-height: 46px;
		}
	}

	&__video-text {
		z-index: 0;
		position: absolute;
		left: 49px;
		bottom: 2rem;
		height: 50px;
		display: flex;
		align-items: center;
		background-color: $color-multnomah-gold;
		transform: translateX(-100%);
		color: $color-white;
		font-weight: $font-weight-bold;
		font-size: 1.4rem;
		padding: 0 2rem 0 2px;
		white-space: nowrap;
		opacity: 0;
		transition: all $default-transition-speed $default-transition-easing;

		@include media-min($medium) {
			bottom: 4rem;
			left: 69px;
			height: 70px;
		}
	}
}
