.banner-search-results {
	.banner-slim__inner {
		@include media-max($medium) {
			padding-bottom: 2rem;
		}
	}

	&__form-container {
		display: flex;
		flex-direction: column;
		width: 100%;

		@include media-min($medium) {
			flex-direction: row;
			align-items: center;
		}

		@include media-min($large) {
			max-width: 620px;
		}

		.form-field-with-icon {
			width: 100%;
		}
	}

	input[type="submit"] {
		margin-top: 1.5rem;

		@include media-min($medium) {
			margin-top: 0;
			margin-left: 1.5rem;
		}
	}
}
