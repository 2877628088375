.block-tabs {
	&__desktop-tab-nav {
		display: none;

		@include media-min($large) {
			display: flex;
			justify-content: center;
			margin: 0 -1rem;
		}

		.block-tabs__tab-button {
			flex-grow: 1;
			width: auto;
			margin: 0 1rem;
		}
	}

	&__tabs-container {
		.block-tabs__tab-button {
			width: 100%;

			@include media-min($large) {
				display: none;
			}
		}

		.block-tabs__tab + .block-tabs__tab-button {
			margin-top: 2rem;

			@include media-min($medium) {
				margin-top: 3rem;
			}
		}
	}

	&__tab-button {
		&::after {
			z-index: -1;
			content: "";
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translate(-50%, -25%) rotate(45deg);
			width: 16px;
			height: 16px;
			background-color: $color-green;
			transition: all $default-transition-speed $default-transition-easing;
		}

		&.active {
			&::after {
				background-color: $color-multnomah-gold;
				transform: translate(-50%, 50%) rotate(45deg);
			}
		}
	}

	&__tab {
		max-height: 0;
		transition: all 0.3s cubic-bezier(0, 1, 0, 1);
		overflow: hidden;
		pointer-events: none;
		background-color: rgba($color-black, 0.05);

		padding: 0 1.5rem;

		@include media-min($medium) {
			padding: 0 4rem;
		}

		@include media-min($large) {
			display: none;
			max-height: none;
			transition: none;
			margin-top: 3.5rem;
		}

		.columns + .columns {
			margin-top: 2rem;

			@include media-min($large) {
				margin-top: 0;
			}
		}

		&.active {
			max-height: 9999px;
			transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
			pointer-events: all;
			margin-top: 2.5rem;
			padding: 2.5rem 1.5rem;

			@include media-min($medium) {
				margin-top: 3rem;
				padding: 4rem;
			}

			@include media-min($large) {
				display: block;
			}
		}
	}
}
