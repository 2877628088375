.tree-nav {
	ul {
		list-style: none;
	}

	li {
		position: relative;
		padding-left: 2.5rem;

		&::before {
			content: "";
			position: absolute;
			top: 35px;
			left: 5px;
			bottom: 5px;
			border-left: 1px dashed rgba($color-black, 0.1);
			opacity: 0;
			transition: opacity $default-transition-speed $default-transition-easing;
		}

		& > ul {
			max-height: 0;
			pointer-events: none;
			transition: all $default-transition-speed cubic-bezier(0, 1, 0, 1);
			overflow: hidden;
		}

		&.expanded > ul {
			max-height: 2000px;
			pointer-events: all;
			transition: all $default-transition-speed cubic-bezier(1, 0, 1, 0);
		}

		&.expanded {
			&::before {
				opacity: 1;
			}

			& > button {
				&::after {
					opacity: 0;
				}
			}
		}

		&.active {
			& > a {
				background-color: $color-multnomah-gold;
				color: $color-white;
			}
		}
	}

	button {
		position: absolute;
		top: 8px;
		left: 0;
		cursor: pointer;
		outline: none;
		width: 12px;
		height: 12px;

		&::before,
		&::after {
			content: "";
			position: absolute;
			background-color: rgba($color-black, 0.5);
		}

		&::before {
			top: 50%;
			left: 0;
			right: 0;
			transform: translateY(-50%);
			height: 2px;
		}

		&::after {
			top: 0;
			left: 50%;
			bottom: 0;
			transform: translateX(-50%);
			width: 2px;
			transition: opacity $default-transition-speed $default-transition-easing;
		}
	}

	a {
		display: block;
		font: normal normal $font-weight-medium 1.6rem / 1.2 $font-poppins;
		color: rgba($black, 0.5);
		text-decoration: none;
		padding: 0.5rem 1rem;
		margin: 0.2rem 0;
		transition: all $default-transition-speed $default-transition-easing;

		&:hover {
			background-color: $color-multnomah-gold;
			color: $color-white;
		}
	}
}
