.tmpl-post-single {
	&__title {
		font-size: 3.2rem;

		@include media-min($medium) {
			font-size: 4rem;
		}

		@include media-min($large) {
			font-size: 4.6rem;
		}
	}
}
