.block-scholarships {
	.block-inner {
		padding: 0;
	}

	&__inner-row {
		@include media-min($large) {
			@include custom-foundation-row-padding-bp("large", false);
		}
	}

	&__nav-container,
	&__content-container {
		padding-top: map-deep-get($block-padding, "small", "top");
		padding-bottom: map-deep-get($block-padding, "small", "bottom");

		@include media-min($medium) {
			padding-top: map-deep-get($block-padding, "medium", "top");
			padding-bottom: map-deep-get($block-padding, "medium", "bottom");
		}

		@include media-min($large) {
			padding-top: map-deep-get($block-padding, "large", "top");
			padding-bottom: map-deep-get($block-padding, "large", "bottom");
		}
	}

	&__nav-container {
		@include custom-foundation-row-padding(false);

		@include media-min($large) {
			position: relative;
			padding-right: 2rem;
			padding-left: 0;

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				transform: translateX(-100%);
				width: 50vw;
				background-color: $color-off-white;
			}
		}
	}

	&__desktop-nav {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		padding-right: 1.5rem;
	}

	&__tab-button {
		font: normal normal $font-weight-medium 1.8rem / 1.1 $font-poppins;
		color: rgba($color-black, 0.25);
		letter-spacing: 0.4px;
		transition: color $default-transition-speed $default-transition-easing;
		cursor: pointer;
		text-align: right;
		outline: 0;

		& + & {
			margin-top: 3rem;
		}

		&:hover,
		&.active {
			color: rgba($color-black, 0.85);
		}
	}

	&__content-container {
		@include media-max($large) {
			padding: 0;
		}

		@include media-min($large) {
			padding-left: 6rem;
		}
	}

	&__tab {
		display: none;

		&.active {
			display: block;
		}
	}

	&__tab-columns {
		@include media-max($large) {
			@include custom-foundation-row-padding(false, ("small", "medium"));

			padding-top: 3rem;
			padding-bottom: 3rem;
		}

		@include media-min($large) {
			padding-left: 0;
			padding-right: 0;
		}

		.column + .column {
			margin-top: 3rem;

			@include media-min($medium) {
				margin-top: 0;
			}
		}
	}

	&__table {
		// table-layout: fixed;
		width: 100%;
		margin: 0;
		padding: 0;
		background-color: transparent;

		th {
			padding: 0;

			@include media-min($large) {
				padding: 0 2rem 0 0;

				&:last-child {
					padding-right: 0;
				}
			}
		}

		td {
			padding: 2rem 0;
			font-size: 1.6rem;

			@include media-min($medium) {
				font-size: 1.8rem;
			}

			@include media-min($large) {
				padding: 2rem 2rem 2rem 0;

				&:last-child {
					padding-right: 0;
				}
			}
		}

		th {
			border-bottom: none;
		}

		td.block-scholarships__table-description {
			font-size: 1.6rem;
			padding: 0 12rem 0 4.2rem;
			color: rgba($color-black, 0.5);
		}

		&--slim {
			td {
				padding: 0.5rem 0;
			}
		}

		tr.block-scholarships__table-row-header {
			cursor: pointer;

			&.active {
				.block-scholarships__icon-plus {
					transform: rotate(45deg);
				}
			}
		}

		tr.block-scholarships__table-row-description {
			display: none;

			&.active {
				display: table-row;
			}
		}
	}

	&__mobile-table-row {
		@include custom-foundation-row-padding(false);

		position: relative;
		display: flex;
		padding-top: 2rem;
		padding-bottom: 2rem;
		transition: background-color $default-transition-speed $default-transition-easing;

		& + & {
			border-top: 1px solid rgba($color-black, 0.1);
		}

		&.active {
			background-color: $color-off-white;

			.block-scholarships__icon-plus {
				transform: rotate(45deg);
			}
		}

		.block-scholarships__table {
			margin-top: 1rem;
		}
	}

	&__mobile-table-row-right-column {
		width: 100%;
	}

	&__mobile-table-row-title {
		font-weight: $font-weight-bold;
		color: rgba($color-black, 0.85);
	}

	&__mobile-table-description {
		max-height: 0;
		overflow: hidden;
		transition: all 0.3s cubic-bezier(0, 1, 0, 1);
		pointer-events: none;

		&.active {
			max-height: 9999px;
			transition: all 0.3s cubic-bezier(1, 0, 1, 0);
			pointer-events: all;
		}

		p {
			font-size: 1.6rem;

			&:first-child {
				margin-top: 1rem;
			}
		}
	}

	&__desktop-table-td-amount {
		display: inline-block;
		white-space: nowrap;
	}

	&__icon-plus {
		min-width: 26px;
		max-width: 26px;
		min-height: 26px;
		max-height: 26px;
		color: $color-multnomah-gold;
		transition: transform $default-transition-speed $default-transition-easing;
		margin-right: 1.2rem;
	}

	label.block-scholarships__mobile-tab-select-label {
		font-size: 1.2rem;
		color: rgba($color-black, 0.85);
		letter-spacing: 0.2px;
	}

	&__th,
	&__td {
		&--type {
			width: 25%;
		}

		&--deadline {
			width: 12%;
		}
	}

	&__td-amount-text {
		display: flex;
	}

	&__mobile-table-title-column {
		@include media-min($medium) {
			width: 40%;
		}
	}

	&__title-row {
		padding-top: 1rem;
		padding-bottom: 1rem;
		background-color: $color-multnomah-green;
	}

	&__title {
		color: $color-white;
	}
}
