.banner-photo {
	display: flex;
	flex-direction: column;

	@include media-min($medium) {
		min-height: 480px;
		justify-content: flex-end;
	}

	@include media-max($medium) {
		.banner__cover-image-container {
			position: relative;
			height: 0;
			padding-top: 78vw;
		}
	}

	&.hide-gradient {
		.banner__cover-image-container {
			&::before {
				display: none;
			}
		}
	}

	&__inner {
		position: relative;
		display: flex;
		height: 100%;
		overflow-x: hidden;
		background-color: $color-multnomah-green;

		@include media-min($medium) {
			background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
		}

		@include media-min($large) {
			overflow-x: visible;
		}
	}

	&__inner-contain {
		@include custom-foundation-row-padding(false);

		margin: 0 auto;
		width: 100%;
		max-width: $global-width;
	}

	&__title-container {
		position: relative;
		padding: 2rem 0;

		@include media-min($medium) {
			padding: 3.5rem 4rem 2rem 0;
			max-width: 65%;
		}

		@include media-min($large) {
			padding: 4.5rem 6rem 4.5rem 0;
			max-width: 45%;
		}

		&.hide-title {
			clip: rect(1px, 1px, 1px, 1px);
			height: 1px; width: 1px; 
			overflow: hidden;
			position: absolute !important;
		}
	}

	&__title {
		color: $color-white;
	}

	&__content {
		padding-left: 0px;
		padding-right: 0px;
	}

	&__buttons {

		@include media-min($medium) {
			margin-bottom: 5rem;
		}
	}
}
