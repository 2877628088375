.site-footer {
	z-index: $z-above-blocks;
	position: relative;

	a {
		text-decoration: none;
		transition: color $default-transition-speed $default-transition-easing;

		&:hover {
			color: $color-multnomah-gold;
		}
	}

	&__inner {
		@include custom-foundation-row-padding(false);

		position: relative;
		width: 100%;
		max-width: $global-width;
		margin: 0 auto;

		&--top {
			display: flex;
			flex-direction: column;
			text-align: center;

			@include media-min($medium) {
				flex-direction: row;
				flex-wrap: wrap;
				text-align: left;
			}

			@include media-min($large) {
				flex-wrap: nowrap;
			}
		}

		&--bottom {
			display: flex;
			flex-direction: column;

			@include media-min($medium) {
				align-items: center;
			}

			@include media-min($large) {
				flex-direction: row;
				justify-content: space-between;
			}
		}
	}

	&__top {
		padding: 3.5rem 0;

		@include media-min($medium) {
			padding: 4rem 0;
		}

		@include media-min($large) {
			padding: 8rem 0 6rem;
		}
	}

	&__top-logo {
		@include media-min($medium) {
			flex-basis: 35%;

			img {
				max-width: 105px;
			}
		}

		@include media-min($large) {
			flex-basis: auto;
			padding-right: 36px;
			border-right: 1px solid $color-holly-green;
			margin-right: 36px;
		}
	}

	&__top-logo-img {
		width: 105px;
		// max-width: 95px;
	}

	&__top-about {
		margin-top: 1.5rem;

		p {
			font-size: 1.8rem;
		}

		.site-footer__section-title {
			display: none;
		}

		@include media-min($medium) {
			flex-basis: 65%;
			margin-top: 0;

			.site-footer__section-title {
				display: block;
			}
		}

		@include media-min($large) {
			padding-right: 36px;
			border-right: 1px solid $color-holly-green;
			margin-right: 36px;
		}
	}

	&__top-popular {
		@include media-min($medium) {
			flex-basis: 65%;

			ul {
				column-count: 2;
			}
		}

		@include media-min($large) {
			padding-right: 36px;
			border-right: 1px solid $color-holly-green;
			margin-right: 36px;
		}
	}

	&__top-students {
		@include media-min($medium) {
			flex-basis: 35%;
		}
	}

	&__top-popular,
	&__top-students {
		margin-top: 3rem;

		a {
			display: inline-block;
			font-size: 16px;
		}

		li + li {
			margin-top: 2rem;
		}

		@include media-min($medium) {
			padding-top: 2.5rem;
			border-top: 1px solid $color-holly-green;

			li + li {
				margin-top: 0.5rem;
			}
		}

		@include media-min($large) {
			margin-top: 0;
			padding-top: 0;
			border-top: 0;
		}
	}

	&__bottom {
		padding: 2.5rem 0;
		text-align: center;
		background-color: $color-holly-green;

		@include media-min($medium) {
			text-align: left;
		}

		ul {
			display: flex;
			flex-direction: column;

			@include media-min($medium) {
				flex-direction: row;
			}
		}

		li {
			a {
				font-size: 1.3rem;
				opacity: 0.8;
				letter-spacing: 0.9px;
				text-transform: uppercase;
			}

			& + li {
				margin-top: 1.5rem;
			}

			@include media-min($medium) {
				& + li {
					margin-top: 0;
					margin-left: 3.8rem;
				}
			}
		}
	}

	&__copyright {
		margin-top: 2.5rem;
		font-size: 1.4rem;
		opacity: 0.8;

		@include media-min($large) {
			margin-top: 0;
		}
	}

	&__credit {
		display: block;
		margin-top: 1.5rem;

		@include media-min($medium) {
			position: relative;
			display: inline-block;
			margin-top: 0;
			margin-left: 1.1rem;

			&::before {
				content: "|";
				margin-right: 1.4rem;
			}
		}
	}

	&__social-links {
		margin-top: 3rem;
		display: flex;
		justify-content: center;
		color: $color-multnomah-gold;

		@include media-min($medium) {
			justify-content: flex-start;
		}
	}

	&__social-link {
		display: inline-block;
		width: 30px;
		height: 30px;
		margin-right: 2rem;

		&:last-child {
			margin-right: 0;
		}

		@include media-min($medium) {
			width: 22px;
			height: 22px;
		}

		svg {
			color: $color-white;
			transition: color $default-transition-speed $default-transition-easing;
		}

		&:hover {
			svg {
				color: $color-multnomah-gold;
			}
		}
	}
}
