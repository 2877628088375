.banner-posts-archive {
	.banner-slim__inner {
		@include media-max($medium) {
			padding-bottom: 2rem;
		}
	}

	&__filter-container {
		display: flex;
		flex-direction: column;

		* + * {
			margin-top: 1.5rem;
		}

		@include media-min($medium) {
			flex-direction: row;

			* + * {
				margin-top: 0;
				margin-left: 1.5rem;
			}
		}
	}

	select {
		border: 1px solid $color-multnomah-gold;
		color: $color-black;
	}
}
