.card-default {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: $color-white;
	border: 1px solid $color-multnomah-gold;
	border-radius: $default-border-radius;
	text-decoration: none;
	color: $text-dark;
	transition: all $default-transition-speed $default-transition-easing;

	&__image-container {
		position: relative;
		height: 80vw;
		max-height: 230px;

		@include media-min($medium) {
			height: 32vw;
		}

		@include media-min($large) {
			height: 230px;
			max-height: none;
		}

		&--16x9,
		&--16x10 {
			height: 0;
			max-height: none;
		}

		&--16x9 {
			padding-top: 56.25%;
		}

		&--16x10 {
			padding-top: 62.5%;
		}

		&--slim {
			height: 210px;
			max-height: none;
		}
	}

	&__image {
		@include image-cover-entire-container;
	}

	&__title {
		display: flex;
		justify-content: space-between;
	}

	&__title-arrow {
		min-width: 15px;
		min-height: 15px;
		max-width: 15px;
		max-height: 15px;
		margin-top: 0.7rem;
		margin-left: 1rem;
		color: $color-white;
		opacity: 0;
		transition: opacity $default-transition-speed $default-transition-easing;
	}

	p {
		font-size: 1.6rem;
	}

	&__title,
	p {
		transition: color $default-transition-speed $default-transition-easing;
	}

	&__content {
		flex-grow: 1;
		padding: 2.5rem;

		.card-default__title {
			color: $color-black;
		}

		p {
			color: $text-dark;
		}
	}

	&__link-title:not(.button) {
		font-weight: $font-weight-bold;
		//letter-spacing: 0.3px;
		color: $color-green;
	}

	&__link-title.button {
		margin-top: 1rem;
	}
}

a.card-default {
	&:hover {
		background-color: $color-multnomah-gold;
		color: $color-white;

		.card-default__title,
		p {
			color: $color-white;
		}

		.card-default__title-arrow {
			opacity: 1;
		}
	}
}
