.menu-dropdown {
	position: absolute;
	bottom: 1px;
	left: 50%;
	width: 100%;
	max-width: calc(#{$global-width} - #{rem-calc($-zf-bp-value)});
	transform: translate(-50%, 100%);
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.3s ease-in-out;

	display: flex;
	justify-content: flex-end;

	&.active {
		opacity: 1;
		pointer-events: all;
	}

	&__inner {
		position: relative;
		width: 100%;
		max-width: 1000px;
		background: $color-white;
		box-shadow: 0 8px 13px rgba($color-black, 0.1);
		padding: 2.5rem 1.6rem 3rem;
	}

	&__links-list {
		display: flex;
		flex-direction: column;
		color: $color-black-header-links;
		font: normal normal $font-weight-medium 1.6rem / 1.25 $font-poppins;
		letter-spacing: 0.3px;
		list-style: none;

		li + li {
			margin-top: 1.4rem;
		}

		a {
			position: relative;
			text-decoration: none;
			color: $color-black-header-links;

			&::after {
				content: "";
				position: absolute;
				bottom: -2px;
				left: 0;
				width: 100%;
				height: 1px;
				background-color: $color-multnomah-gold;
				opacity: 0;
				pointer-events: none;
				transition: opacity $default-transition-speed ease-in-out;
			}

			&:hover {
				&::after {
					opacity: 1;
				}
			}
		}
	}

	.image-link + .image-link {
		margin-top: 3rem;
	}

	.image-link__title {
		font-size: 1.6rem;
	}

	.image-link__content {
		font-size: 1.4rem;
	}
}
