.button,
.wysiwyg button,
input[type="submit"],
.mce-button-primary,
%button {
	position: relative;
	display: inline-block;
	padding: 1.9rem 3rem 0;

	@supports (display: inline-flex) {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding: 0 3rem;
	}

	margin: 0;
	min-width: 160px;
	min-height: $default-input-min-height;
	border: 0;
	border-radius: $default-border-radius;

	font: normal normal $font-weight-medium 1.4rem / 1.45 $font-primary;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	letter-spacing: 0.5px;

	color: $color-white;
	background-color: $color-green;

	appearance: none;
	outline: none;
	cursor: pointer;
	transition: all $default-transition-speed ease-in-out;

	// @include dark-bg() {
	// 	background-color: $color-white;
	// 	border-color: $color-white;
	// 	color: $color-black;
	// }

	&:hover,
	&:active,
	&.active {
		color: $color-white;
		background-color: $color-multnomah-gold;
		text-decoration: none;
	}

	&:disabled,
	&.disabled {
		pointer-events: none;
		cursor: not-allowed;
		color: rgba($color-white, 0.7);
		background-color: rgba($color-green, 0.7);
	}

	&.button--secondary {
		color: $color-black;
		background-color: transparent;
		border: 2px solid $color-black;

		// @include dark-bg() {
		// 	color: $color-white;
		// 	border-color: $color-white;
		// }

		&:hover,
		&:active,
		&.active {
			color: $color-white;
			background-color: darken($color-green, 10%);
			border-color: darken($color-green, 10%);
		}

		&:disabled,
		&.disabled {
			color: rgba($color-black, 0.4);
			background-color: transparent;
			border-color: rgba($color-black, 0.4);

			// @include dark-bg() {
			// 	color: rgba($color-white, 0.4);
			// 	border-color: rgba($color-white, 0.4);
			// }
		}
	}
}

.button {
	&--has-icon {
		min-width: 0;
		padding-left: 6.5rem;
	}

	&__icon-container {
		display: flex;
		justify-content: center;
		align-items: center;

		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: $default-input-min-height;

		color: $color-white;
	}

	&__icon {
		width: #{$default-input-min-height - 18px};
		height: #{$default-input-min-height - 18px};
		color: $color-white;
	}

	&--slim {
		min-width: 125px;
		min-height: 0;
		padding: 0.4rem 1.5rem;
	}
}

button.button {
	padding-top: 0;
}

input[type="submit"] {
	padding-top: 0;
}
