$modal-close-sm: 34px;
$modal-close-lg: 44px;

html.modal-active {
	overflow-y: hidden;
}

.modal-backdrop {
	z-index: $z-above-all;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba($color-black, 0.9);
	transition: opacity ($default-transition-speed * 2) $default-transition-easing;
	opacity: 0;
	pointer-events: none;

	html.modal-active & {
		opacity: 1;
		pointer-events: all;
	}
}

.modal {
	position: relative;
	background-color: $color-white;
	width: 100%;
	height: 100%;
	overflow: visible;

	display: flex;
	justify-content: center;

	@include media-min($medium) {
		margin: 4rem auto 0;
		height: auto;
		max-width: 80vw;
		max-height: calc(100% - 8rem);
	}

	@include media-min($large) {
		max-width: 800px;
	}

	&__close {
		z-index: 1;
		position: absolute;
		top: 0;
		right: 0;

		display: flex;
		justify-content: center;
		align-items: center;
		color: $color-white;
		background: $color-multnomah-gold;
		cursor: pointer;

		width: $modal-close-sm;
		height: $modal-close-sm;

		@include media-min($medium) {
			transform: translate(50%, -50%);
		}

		@include media-min($large) {
			width: $modal-close-lg;
			height: $modal-close-lg;
		}
	}

	&__close-icon {
		width: #{$modal-close-sm / 1.75};
		height: #{$modal-close-sm / 1.75};

		@include media-min($large) {
			width: #{$modal-close-lg / 1.75};
			height: #{$modal-close-lg / 1.75};
		}
	}

	&__content-container {
		position: relative;
		width: 100%;
		overflow-y: scroll;
	}

	&__loading-container,
	&__content {
		padding: 4rem 2rem;

		@include media-min($large) {
			padding: 6rem 8rem;
		}
	}

	&__loading-container {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		opacity: 0;
		pointer-events: none;
		transition: opacity $default-transition-speed $default-transition-easing;
	}

	// &__content {
	// 	opacity: 1;
	// 	pointer-events: none;
	// 	transition: opacity $default-transition-speed $default-transition-easing;
	// }

	&.is-loading {
		.modal__loading-container {
			opacity: 1;
			pointer-events: all;
		}

		.modal__content {
			opacity: 0;
			pointer-events: none;
		}
	}
	&.modal-pardot-form {
		iframe {
			min-height: calc(100vh - 16rem);
			overflow: auto;
		}
	}
}

/**
 * Video Modal
 */
.modal {
	&__video-container {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;

		width: 100%;
	}

	&__video-embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		max-height: 90vh;
	}
}

.modal-video {
	height: auto;

	@include media-max($medium) {
		margin-top: 8rem;

		.modal__close {
			transform: translateY(-100%);
		}
	}

	.modal__content {
		padding: 0;
	}

	// .modal__loading-container,
	// .modal__content {
	// 	padding: 0;
	// }
}
