.card-search {
	display: flex;
	flex-direction: column;
	text-decoration: none;

	color: rgba($color-black, 0.5);

	& + & {
		margin-top: 4.5rem;
	}

	&__title {
		color: $color-multnomah-green;
		transition: color $default-transition-speed $default-transition-easing;

		// font-family: Roboto-Bold;
		font-size: 2.2rem;
		color: $color-black;
		//letter-spacing: 0.5px;
	}

	&:hover {
		.card-search__title {
			color: $color-multnomah-gold;
		}
	}
}
