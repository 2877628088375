.block-title {
	text-align: center;

	&__inner {
		transition: opacity $default-animation-speed $default-transition-easing;

		.block-animate & {
			opacity: 0;
		}
	}

	@include media-min($large) {
		.block-inner {
			padding: 5rem 0;
		}
	}

	&__row-content {
		display: flex;
		flex-direction: column;
		align-items: center;

		@include media-min($large) {
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: flex-start;
		}
	}

	&__column-title,
	&__column-content {
		@include grid-column-gutter;
	}

	&__column-title {
		@include media-min($medium) {
			flex-basis: 66.67%;
		}

		@include media-min($large) {
			flex-basis: 41.6%;
			text-align: right;
		}
	}

	&__column-content {
		margin-top: 2rem;

		@include media-min($large) {
			flex: 1 0;
			max-width: 50%;
			margin-top: 0;
			text-align: left;
		}
	}
}
