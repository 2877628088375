.block-accordion-with-cta {
	.block-inner {
		padding: 0;
	}

	&__left-column,
	&__right-column {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	&__right-column {
		@include media-min($large) {
			position: relative;
			padding-right: 5rem;

			&::before,
			&::after {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				background-color: $color-off-white;
			}

			&::before {
				left: -30px;
				width: 30px;
			}

			&::after {
				right: 0;
				width: 50vw;
				transform: translateX(100%);
			}
		}
	}

	&__left-button {
		margin-top: 3rem;
	}

	&__image-links-row {
		.column + .column {
			margin-top: 3rem;

			@include media-min($medium) {
				margin-top: 0;
			}

			@include media-min($large) {
				margin-top: 3rem;
			}
		}
	}

	&__button-group {
		margin-top: 3rem;

		& + & {
			margin-top: 3rem;

			@include media-min($medium) {
				margin-top: 4rem;
			}

			@include media-min($large) {
				margin-top: 5rem;
			}
		}

		.button {
			width: 100%;

			@include media-min($medium) {
				width: auto;
			}
		}
	}

	&__button-title {
		margin-bottom: 1rem;
	}
}
