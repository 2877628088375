.tmpl-search {
	padding-top: 6rem;
	padding-bottom: 6rem;

	.archive__cards-container {
		margin-top: 6rem;
	}

	&__results-title {
		font-size: 2.6rem;
		color: rgba($color-black, 0.5);
		//letter-spacing: 0.5px;
		line-height: 1.3;
	}
}
