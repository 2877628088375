.breadcrumbs {
	display: flex;
	align-items: center;

	& > * {
		& + * {
			margin-left: 2rem;
		}
	}

	a {
		font-size: 1.2rem;
		letter-spacing: 0.25px;
		color: #565656;
		text-decoration: none;
		transition: color $default-transition-speed $default-transition-easing;

		&:hover {
			color: $color-multnomah-gold;
		}
	}

	&__icon {
		color: $color-multnomah-gold;
		width: 12px;
		height: 12px;
	}
}
