.block-links {
	&__row-links {
		margin-top: 2rem;

		.columns {
			transition: opacity $default-animation-speed $default-transition-easing;

			.block-animate & {
				opacity: 0;
			}
		}

		.columns:first-child {
			.block-links__link {
				border-top: 1px solid rgba($color-black, 0.1);
			}
		}

		@include media-min($medium) {
			margin-top: 3rem;

			.columns:nth-child(-n + 2) {
				.block-links__link {
					border-top: 1px solid rgba($color-black, 0.1);
				}
			}
		}

		@include media-min($large) {
			.columns:nth-child(-n + 3) {
				.block-links__link {
					border-top: 1px solid rgba($color-black, 0.1);
				}
			}
		}
	}

	&__link {
		position: relative;
		display: block;
		height: 100%;
		text-decoration: none;
		padding-top: 3rem;
		padding-bottom: 3rem;
		border-bottom: 1px solid rgba($color-black, 0.1);

		&:hover {
			.block-links__link-title-arrow {
				transform: translateX(5px);
			}
		}
	}

	&__link-title {
		display: flex;
		align-items: center;
	}

	&__link-title-arrow {
		min-width: 15px;
		min-height: 15px;
		max-width: 15px;
		max-height: 15px;
		margin: 0 0 0 1.5rem;
		color: $color-multnomah-gold;
		transition: transform $default-transition-speed $default-transition-easing;
	}

	&__link-description {
		margin-top: 1rem;
	}
}
