.block-columns {
	@include stacked-column-spacing(3rem, 3rem, 0);

	&.block-animate {
		.col-content {
			opacity: 0;
		}
	}

	.col-content {
		position: relative;
		transition: opacity $default-animation-speed $default-transition-easing;

		&.effect--top-line {
			padding-top: 3.5rem;
		}
	}
	
}
@media only screen and (min-width: 641px){
		#college-preview-table-mobile{
			display: none;
		}	
	}