.icon-link {
	&__label {
		display: block;
		font-weight: $font-weight-medium;
	}

	&__link {
		display: flex;
		align-items: center;
		text-decoration: none;
	}

	&__icon {
		width: 20px;
		height: 20px;
		margin-right: 1rem;
	}
}
