:root {
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

body {
	overflow-x: hidden !important;
	margin-top: $header-height-sm;

	@include media-min($medium) {
		margin-top: $header-height-md;
	}

	@include media-min($large) {
		margin-top: $header-height-lg;
		transition: margin-top ($default-transition-speed * 2) ease-in-out;

		html.desktop-search-active & {
			margin-top: #{$header-height-lg + $search-container-height};
		}
	}

	&.expand-main-content,
	&.expand-footer {
		display: flex;
		flex-direction: column;
		min-height: calc(100vh - #{$header-height-sm});

		@include media-min($medium) {
			min-height: calc(100vh - #{$header-height-md});
		}

		@include media-min($large) {
			min-height: calc(100vh - #{$header-height-lg});
		}
	}

	&.expand-main-content .main-content {
		flex-grow: 1;
	}

	&.expand-footer .site-footer {
		flex-grow: 1;
	}
}

// Apple believes it has the authority to adjust font sizes it deems as "too small"
// on iOS. This removes that hostile behavior.
@media screen and (max-device-width: $medium) {
	body {
		-webkit-text-size-adjust: 100%;
	}
}

ul {
	padding: 0;

	nav & {
		list-style: none;
		list-style-position: inside;
	}
}

table {
	background-color: rgba($color-black, 0.05);
	color: $color-table-text;
	font-size: 1.8rem;
	padding: 2rem 1rem;

	@include media-min($medium) {
		padding: 2rem;
	}

	@include media-min($large) {
		padding: 2.5rem 3rem;
	}

	thead {
		tr {
			th {
				border-bottom: 1px solid $color-black-25;
				padding: 0.5rem;
				text-align: left;
			}
		}
	}

	tbody {
		tr {
			@include media-max($medium) {
				display: flex;
				flex-direction: column;
			}

			td {
				vertical-align: top;
				padding: 0.5rem;
				text-align: left;
			}

			td+td {
				@include media-min($medium) {
					padding-left: 2rem;
				}
			}
		}
	}
}

blockquote {
	padding: 2.5rem 1.5rem;
	background-color: $color-multnomah-green;

	@include media-min($medium) {
		padding: 2.5rem 4rem;
	}

	@include media-min($large) {
		padding: 4rem;
	}

	p,
	cite {
		font: inherit;
		color: $color-white;
	}

	p {
		font-size: 2.4rem;
		line-height: 1.4;
	}

	cite {
		display: block;
		margin-top: 2rem;
		font-weight: $font-weight-bold;
		font-size: 1.8rem;
		line-height: 1.1;
		letter-spacing: 0.3px;
	}

	&.color-gold {
		background-color: rgba($color-multnomah-gold, 0.9);
	}
}

code {
	background: $color-black;
	color: $color-white;
	padding: 0.1rem 0.25rem;
}

strong {
	font-weight: $font-weight-bold;
}

video,
iframe {
	max-width: 100%;
}

.row--with-padding {
	@include custom-foundation-row-padding;
}
