.block-content-side-cta {
	&__col-content {
		position: relative;
		padding-top: 2rem;
	}

	&__col-cta {
		margin-top: 3rem;

		@include media-min($medium) {
			margin-top: 5rem;
		}

		@include media-min($large) {
			margin-top: 0;
		}
	}

	&__col-content,
	&__col-cta {
		transition: opacity $default-animation-speed $default-transition-easing;

		.block-animate & {
			opacity: 0;
		}
	}

	&__cta-container {
		background-color: $color-multnomah-green;
		padding: 2.5rem 2rem;

		@include media-min($medium) {
			padding: 4rem 3.5rem;
		}

		@include media-min($large) {
			margin: 0 5rem;
			padding: 4.5rem 4rem;
		}

		.button {
			margin-top: 3rem;
			min-width: 250px;
		}
	}
}
