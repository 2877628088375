.block-dates-details {
	&__row-title {
		&--button {
			margin-bottom: 4rem;
		}

		&--description {
			margin-bottom: 5rem;
		}
	}

	&__title-container {
		display: flex;
		flex-direction: column;

		&--description {
			align-items: center;
			text-align: center;
		}

		&--button {
			justify-content: space-between;
			align-items: center;

			.button {
				margin-top: 2rem;
			}

			@include media-min($large) {
				flex-direction: row;

				.button {
					margin-top: 0;
				}
			}
		}
	}

	&__row-detail-items {
		margin-top: -3rem;

		.columns {
			margin-top: 3rem;

			@for $i from 0 through 3 {
				&:nth-child(#{$i + 1}) {
					/* prettier-ignore */
					transition: opacity #{$default-animation-speed-unitless / 2}s $default-transition-easing #{($default-animation-speed-unitless / 2) * $i}s;
				}
			}

			.block-animate & {
				opacity: 0;
			}
		}
	}

	&__detail-item-text {
		margin-top: 3rem;
		text-align: center;

		@include media-min($large) {
			text-align: right;
		}
	}

	.image-link {
		height: 100%;
	}

	.image-link__content {
		margin-top: 1.5rem;

		p {
			color: $color-white;
		}

		p + * {
			margin-top: 0.5rem;
		}
	}
}
