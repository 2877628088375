.banner-slim {
	&__inner {
		@include custom-foundation-row-padding(false);

		position: relative;
		padding-top: 4.5rem;
		padding-bottom: 5.5rem;

		@include media-min($medium) {
			padding-top: 6rem;
			padding-bottom: 7rem;
		}

		@include media-min($large) {
			padding-top: 11.5rem;
			padding-bottom: 11rem;
		}
	}

	&__inner-contain {
		@include media-min($large) {
			@include custom-foundation-row-padding(false);

			margin: 0 auto;
			width: 100%;
			max-width: $global-width;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;

		@include media-min($medium) {
			align-items: flex-start;
		}

		@include media-min($large) {
			flex-direction: row;
			align-items: center;
		}
	}

	&__title {
		flex-grow: 1;

		@include media-min($large) {
			& + * {
				margin-top: 0;
			}
		}
	}

	&__button {
		margin-top: 2rem;

		@include media-min($medium) {
			margin-top: 4rem;
		}

		@include media-min($large) {
			margin-top: 0;
			margin-left: 8rem;
		}
	}

	.banner__cover-image-container {
		&::before {
			background-image: none;
			background-color: rgba($color-multnomah-green, 0.85);
		}
	}

	&__breadcrumbs {
		margin-bottom: 2rem;
	}
}
